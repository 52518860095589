$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1700px;
/* ====== MEDIA QUERIES ======*/
@mixin for-mobile-only {
  @media (max-width: $sm - 1) {
    @content;
  }
}
@mixin for-mobile-up {
  @media (min-width: $sm) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $md) {
    @content;
  }
}
@mixin for-tablet-portrait-down {
  @media (max-width: $md - 1) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media all and (min-width: $lg) {
    @content;
  }
}
@mixin for-tablet-landscape-down {
  @media (max-width: $lg - 1) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $xl) {
    @content;
  }
}
@mixin for-desktop-down {
  @media (max-width: $xl - 1) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: $xxl) {
    @content;
  }
}

@mixin for-mouse-devices {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin for-touchscreens-devices {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin mobile-landscape {
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    @content;
  }
}
@mixin mobile-portrait {
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    @content;
  }
}

@mixin for-portrait-screens {
  @media only screen and (orientation: portrait) {
    @content;
  }
}
@mixin for-landscape-screens {
  @media only screen and (orientation: landscape) {
    @content;
  }
}
